#whiteWaitlistInput::placeholder {
  color: #ffffff;
  opacity: 1;
}

#normalWaitlistInput::placeholder {
  color: #2A836C;
  opacity: 1;
}

#greenWaitlistInput::placeholder {
  color: #2A836C;
  opacity: 1;
}

input {
  border-radius: 0;
}

input[type="search"] {
  -webkit-appearance: none;
}

.result-image {
	position: relative;
	width: 100%;		/* desired width */
}
.result-image:before {
	content: "";
	display: block;
	padding-top: 100%; 	/* initial ratio of 1:1*/
}

html {
  background-color: #F0FFF5;
  -webkit-text-size-adjust: 100%;
  /* background: linear-gradient(0deg, rgba(0,185,136,1) 0%, rgba(240,255,245,1) 100%);  */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-family: 'Lora', serif;
  font-weight: 700;
  font-size: 2rem;
  margin: 0;
}

h2 {
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
}

h3 {
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

h4 {
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 1rem;
  margin: 0;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* lora-regular - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lora-v26-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-500 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/lora-v26-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-500.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-600 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/lora-v26-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-600.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-700 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lora-v26-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-700.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lora-v26-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-500italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/lora-v26-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-500italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-600italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/lora-v26-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-600italic.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-700italic - latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/lora-v26-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/lora-v26-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lora-v26-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lora-v26-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lora-v26-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lora-v26-latin-700italic.svg#Lora') format('svg'); /* Legacy iOS */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
